import { timeToAppFormat } from '@/utils'
export default {
  props: {
    datetime: {
      type: String
    },
    minDate: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    forBooking: {
      type: Boolean,
      default: true
    },
    showAlert: {
      type: Boolean,
      default: true
    },
  },
  computed: {
  },
  created() {
  //  console.log("start_picker_mixin.js created ", this.pickedDate, this.datetime)
  },
  mounted() {
    //console.log("start_picker_mixin.js mounted ", this.pickedDate, this.datetime, this.minDate)
    try {
      if (this.datetime && (this.datetime.length > 0)) {
        this.$emit('start-picked', Date.parse(this.datetime).toString('MMMM dS, yyyy h:mm tt'))
      }
    }
    catch(err) {
      console.error(err)
    }
  },
  data() {
    return {
      activeProgress: null
    }
  },
  methods: {
    onStartPicked(newTime, status) {
      //console.log("start_picker_mixin.js onStartPicked", newTime, status)
      this.showPicker = false
      this.$emit('start-picked', Date.parse(newTime).toString('MMMM dS, yyyy h:mm tt'), status)
    }
  },

}
